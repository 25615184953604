<template>
  <BlankLayout class="bg1">
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col>
          <form>
            <h2 class="text-center my-4">{{ pageTitle }}</h2>
            <v-card max-width="300" class="mx-auto">
              <v-card-text>
                <div class="p-2">
                  <v-text-field
                    v-model="confirmationCode"
                    :error-messages="confirmationCodeErrors"
                    :counter="6"
                    label="Masukkan Kode Konfirmasi"
                    required
                    @input="$v.confirmationCode.$touch()"
                    @blur="$v.confirmationCode.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    type="password"
                    :error-messages="passwordErrors"
                    label="Password"
                    required
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="password2"
                    type="password"
                    :error-messages="password2Errors"
                    label="Password"
                    required
                    @input="$v.password2.$touch()"
                    @blur="$v.password2.$touch()"
                  ></v-text-field>
                </div>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  class="mr-4"
                  @click="submit"
                  block
                  :color="$store.state.appThemeColor"
                  dark
                >
                  Reset Password
                </v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-btn text link :to="{name: 'Login' }" block>Login</v-btn>
              </v-card-actions>
            </v-card>
          </form>
        </v-col>
      </v-row>
    </v-container>
  </BlankLayout>
</template>

<script>
import BlankLayout from "@/layouts/BlankLayout.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ForgetConfirmation",
  components: { BlankLayout },
  mixins: [validationMixin],

  validations: {
    confirmationCode: { required },
    password: { required },
    password2: { required },
  },

  data: () => ({
    confirmationCode: "",
    password: "",
    password2: "",
    pageTitle: 'Konfirmasi'
  }),

  computed: {
    confirmationCodeErrors() {
      const errors = [];
      if (!this.$v.confirmationCode.$dirty) return errors;
      !this.$v.confirmationCode.required && errors.push("Kode Konfirmasi tidak boleh kosong.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password tidak boleh kosong.");
      return errors;
    },
    password2Errors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password tidak boleh kosong.");
      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
    },
    clear() {
      this.$v.$reset();
      this.confirmationCode = "";
    },
  },
};
</script>


<style scoped>
.bg2 {
  background: #536976; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #292e49,
    #536976
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #292e49,
    #536976
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
h2 {
  color: #ffffff;
}
</style>